<p>Please submit any questions, complaints, or appeals via the form below.  Include as much information as possible.
    You will be contacted by email to follow up.  Remember the form submission is NOT anonymous.
</p>

<div *ngIf="submitSuccessFlag" class="alert alert-success">
Issue submitted successfully.
<button type="button" class="btn btn-primary text-white" (click)="resetForm()">Reset Form</button>
</div>

<form #complainForm="ngForm" (ngSubmit)="onSubmit(complainForm)" *ngIf="!submitSuccessFlag">
    <div class="grid">
        <div class="col-12">
            <label for="reason">Reason for using this form:</label>
            <select [(ngModel)]="reason" id="reason" style="max-width:250px;" class="form-select" name="reasonCtl" required #reasonCtl="ngModel">
                <option value=""></option>
                <option value="products">Products</option>
                <option value="Proficiency Testing">Proficiency Testing Program</option>
                <option value="other">Other</option>
            </select>
            <div *ngIf="reasonCtl.invalid && (reasonCtl.dirty || reasonCtl.touched)" class="alert alert-danger" style="max-width:250px">Reason is required</div>
        </div>
        
        <div *ngIf="reason == 'other'" class="col-12">
            <label for="otherReason">Please indicate other reason:</label>
            <input type="text" class="form-control" style="max-width:250px" id="otherReason" name="otherReasonCtl" #otherReasonCtl="ngModel" [(ngModel)]="otherReason"/>
            <div *ngIf="reasonCtl.value =='other' && otherReasonCtl.value =='' &&(otherReasonCtl.dirty || otherReasonCtl.touched)" 
            class="alert alert-danger" style="max-width: 250px;">Please provide another reason!</div>
        </div>
        
        <div *ngIf="reason == 'products'" class="col-12">
            <label for="product">Name of product(s):</label>
            <input type="text" class="form-control" style="max-width:250px;" id="product" name="productCtl" #productCtl="ngModel" [(ngModel)]="product"/>
            <div *ngIf="reasonCtl.value =='products' && productCtl.value =='' &&(productCtl.dirty || productCtl.touched)" 
            class="alert alert-danger" style="max-width: 250px;">Please provide a product!</div>
        </div>

        <!--FORM FOR PROFICIENCY TESTING QUESTIONS-->
        <ng-container *ngIf = "reason == 'Proficiency Testing'">
            <div class="col-12">
                <div *ngIf="!roundsLoaded" class="mt-3 mb-3 bg-info" style="max-width:250px;">Loading Study Rounds, please wait..</div>
                <div [hidden]="!roundsLoaded">
                    <label for="roundId" class="form-label">Round Id:</label>
                    <select [(ngModel)] = "roundId" name="roundCtl" class="form-select" style="max-width:250px" id="roundId" #roundCtl="ngModel">
                        <option *ngFor="let r of rounds" [value] = "r.roundName">{{r.roundName}}</option>
                        <option value="Other">Other</option>
                    </select>
                    <div *ngIf = "roundCtl.value == '' && (roundCtl.dirty || roundCtl.touched)" class="alert alert-danger" style="max-width:250px">Round is required</div>
                </div>
            </div>
            <div [hidden] = "roundId != 'Other'">
                <label for = "otherRound">Please enter other round Id:</label>
                <input type = "text" class="form-control" name = "otherRoundCtl" id="otherRound" style="max-width:250px;" #otherRoundCtl="ngModel" [(ngModel)]="otherRound"/>
                <div *ngIf = "roundCtl.value =='other' && otherRoundCtl.value =='' &&(otherRoundCtl.dirty || otherRoundCtl.touched)" 
                class="alert alert-danger" style="max-width: 250px;">Please indicate a testing round!</div>
            </div>
        
            <div class="col-12">
                <label for="participantId" class="form-label">Participant Id:</label>
                <input [(ngModel)]="participantId" name="participantId" type="number" class="form-control" style="max-width:250px"/>
            </div>
            <div class="col-12">
                <label for="category">Area of Concern:</label>
                <select class="form-select" style="max-width:250px" name="categoryCtl" id="category" [(ngModel)]="category" #categoryCtl="ngModel">
                    <option value=""></option>
                    <option value="Logistics">Logistics</option>
                    <option value="Test Material">Test Material</option>
                    <option value="PT Results">PT Results</option>
                    <option value="Other">Other</option>
                </select>
                <div *ngIf="categoryCtl.value =='' && (categoryCtl.dirty || categoryCtl.touched)" class="alert alert-danger" style="max-width:250px">Reason is required</div>
            </div>
            
            <div *ngIf="category == 'Other'">
                <label for="otherCategory">Enter other reason:</label>
                <input type="text" class="form-control" style="max-width:250px;" name="otherCategoryCtl" id="otherCategory" #otherCategoryCtl/>
                <div *ngIf="categoryCtl.value =='other' && otherCategoryCtl.value =='' &&(otherCategoryCtl.dirty || otherCategoryCtl.touched)" 
                class="alert alert-danger" style="max-width: 250px;">Please indicate a testing round!</div>
            </div>
            </ng-container>
        <ng-container *ngIf="reason != ''">
            <div class="col-12" *ngIf="reason != ''">
                <label for="comments">Comments: </label>
                <textarea [(ngModel)] = "issue" rows="10" cols="80" spellcheck="true" 
                class="form-control" name="issue" id="comments" autocomplete="on"
                autocorrect="on" maxlength="1000" placeholder="Type in here...." required ></textarea>
            </div>
            <div class="col-12" style="display:flex; align-items:baseline; justify-content: space-between;">
                <span [style.color]="(issue.length == 1000) ? 'red' : 'black'" 
                [style.font-weight]="(issue.length > 899) ? '700' : '400'">{{issue.length}}/1000 characters</span>
                <button [disabled]="complainForm.invalid" type="submit" class="btn btn-primary text-white mt-2">Submit</button>
            </div>
        </ng-container>
    </div>
</form>
<div *ngIf="hasErrors" class="alert alert-danger mt-2">
    Form is missing some information!
</div>