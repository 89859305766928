import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes} from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { ProductsmainComponent } from './components/products/productsmain/productsmain.component';
import { GalleryComponent } from './components/products/gallery/gallery.component';
import { DetailComponent } from './components/products/detail/detail.component';
import { ShippingComponent } from './components/shipping/shipping.component';

import { CartmainComponent } from './components/cart/cartmain/cartmain.component';
import { VerifyorderComponent } from './components/cart/verifyorder/verifyorder.component';
import { AddressesComponent } from './components/cart/addresses/addresses.component';
import { QuestionsComponent } from './components/cart/questions/questions.component';
import { FinalizeComponent } from './components/cart/finalize/finalize.component';
import { ProfstudyComponent } from './components/profstudy/profstudy.component';
import { ProfstudydetailComponent } from './components/profstudy/profstudydetail/profstudydetail.component';
import { RouteguardService } from './services/routeguard/routeguard.service';
import { OrdersComponent } from './components/orders/orders.component';
import { OrderDetailsComponent } from './components/orders/orderdetails/orderdetails.component';
import { AuthenticatepageComponent } from './components/authenticatepage/authenticatepage.component';
import { AccreditationComponent } from './components/accreditation/accreditation.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { ExtendedNewsComponent } from './components/extendednews/extendednews.component';
import { TsrcComponent } from './components/tsrc/tsrc.component';
import { ComplaintsComponent } from './components/complaints/complaints.component';

const appRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'products', component: ProductsmainComponent,
     children: [
      { path: 'gallery/:id', component: GalleryComponent },
      { path: 'gallery/:category/detail/:id', component: DetailComponent}
    ]
  },
  { path: 'announcements', component: ExtendedNewsComponent},
  { path: 'tsrc', component : TsrcComponent},
  { path: 'shipping', component: ShippingComponent },
  { path: 'iso_accreditation', component: AccreditationComponent},
  { path: 'documents', component: DocumentsComponent
  },
  {path:'support', component: ComplaintsComponent, canActivate: [RouteguardService]},
  { path: 'cart', component: CartmainComponent,
    canActivate: [RouteguardService],
    children: [
      { path: 'cart', component: VerifyorderComponent },
      { path: 'verifyorder', component: VerifyorderComponent },
      { path: 'addresses', component: AddressesComponent },
      { path: 'questions', component: QuestionsComponent },
      { path: 'finalize', component: FinalizeComponent }
    ]
  },
  {path: 'orders', component: OrdersComponent,
    canActivate: [RouteguardService],
    children:[{path:':purchaseId', component: OrderDetailsComponent}]
  },
  {path: 'profstudies', component: ProfstudyComponent,
  canActivate: [RouteguardService],
   children: [
     {path: ':roundName', component: ProfstudydetailComponent}
   ]},
   {path: 'authenticate', component: AuthenticatepageComponent, canActivate: [RouteguardService]},
   {path: '404', component:NotfoundComponent},
   {path: '**', redirectTo : "/404"}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(
      appRoutes
     ,
      {
         //enableTracing: true, // <-- debugging purposes only
         //preloadingStrategy: PreloadAllModules
       }
    )
  ],
  declarations: []
})
export class AppRoutingModule { }