
<div class="row" *ngIf="showCart; else noItems">
  <div class="col-md-8">
    <form *ngIf="canEdit != true; else staticQuantity" [formGroup]="cartForm">
      
      <div class="card flex-md-row mb-4 box-shadow h-md-500" *ngFor="let item of cart.CartItems; let i = index">
      <a routerLink="/products/gallery/{{item.category}}/detail/{{item.productId}}">
        <img class="card-img-left flex-auto d-none d-md-block m-3" data-src="../../assets/img/{{item.image}}" height='150' alt="">
      </a>
      <div class="card-body d-flex flex-column align-items-start">
        <a routerLink="/products/gallery/{{item.category}}/detail/{{item.productId}}">
        <h5 class="card-title"> {{item.productName}}</h5>
          </a>
        <p class="card-text">
          Unit Price: {{item.unitPrice | currency }} 
        </p>
        <ng-container formArrayName="items">
        <ng-container formGroupName="{{i}}">
          <label for="quantity">Quantity</label>
          <input type="number" name="quantity" formControlName="quantity" min="1" (change)="updateSubtotal($event,i)">
          <ngb-alert type="danger" [dismissible]="false" *ngIf="items.controls[i].invalid && (items.controls[i].dirty || items.controls[i].touched)">
            Quantity cannot be &lt; 1 or contain decimals.</ngb-alert>
        </ng-container>
      </ng-container>
          <ng-template #staticQuantity>
              <p>Quantity: {{item.quantity}} <span *ngIf="item.quantity === 1"> {{ item.unit }} </span> <span *ngIf="item.quantity > 1"> {{ item.unitPlural }} </span></p>
            </ng-template>
        <h6>
          Total: {{item.subtotal | currency}}
        </h6>
        <button type="button" *ngIf="canEdit != true" (click)="removeFromCart($event, item)" class="btn btn-secondary btn-sm mt-1">Remove from Cart</button>
      </div>
    </div>
  
    <h4 class="bg-light p-2 text-right" *ngIf="cartTotal">
      Cart Total: {{ cartTotal | currency }}
    </h4>
  </form>
  </div>
  <div class="col-md-4 mx-auto" [hidden]="canEdit">
      <a *ngIf="cartForm.invalid" routerLink="null" class="btn btn-primary disabled btn-lg mt-0 ms-3 ps-5 pe-5 text-white">Continue >></a>
      <a *ngIf="cartForm.valid" routerLink="/cart/addresses" class="btn btn-primary btn-lg mt-0 ms-3 ps-5 pe-5 text-white">Continue >></a>
  </div>
</div>

<ng-template #noItems>

  <p class="bg-danger p-3 m-2 h5 text-white" style="max-width: 550px">You have not added any items to your shopping cart yet!</p>
    <a routerLink="/products/gallery/Reference%20Cigarettes" class="btn btn-primary mt-0 ms-3 text-white">View Products</a>
  
</ng-template>

