<div *ngIf="certifiedState > 0">
  <h3>Certified {{productsService.activeCategory}}</h3>
  <hr/>
<div class="comtainer-fluid">
  <div class="row mb-2">
    <ng-container *ngFor="let product of products">
    <div *ngIf="product.isCertified" class="col-sm-4 mb-4 text-center" style="cursor: pointer" [class.selected]="product === selectedProduct"
      (click)="onSelect(product)">
        <img *ngIf="product.image !=null" src="../../assets/img/{{product.image}}" alt="{{product.name}}" style="width:100%;"/> 
        <figcaption class="figure-caption text-center">
        {{ product.name }}
      </figcaption>
      <p *ngIf="product.image == null"></p>
    </div>
  </ng-container>
  </div>
</div>
</div>

<ng-container *ngIf="certifiedState == 1">
  <h3>{{productsService.activeCategory}}</h3>
  <hr/>
</ng-container>
<div class="comtainer-fluid">
  <div class="row mb-2">
    <ng-container *ngFor="let product of products">
    <div *ngIf="!product.isCertified" class="col-sm-4 mb-4 text-center" style="cursor: pointer" [class.selected]="product === selectedProduct"
      (click)="onSelect(product)">
        <img *ngIf="product.image !=null" src="../../assets/img/{{product.image}}" alt="{{product.name}}" style="width:100%;"/> 
        <figcaption class="figure-caption text-center">
        {{ product.name }}
      </figcaption>
      <p *ngIf="product.image == null"></p>
    </div>
  </ng-container>
  </div>
</div>
