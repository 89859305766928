import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ProfstudyService } from '../../services/profstudy/profstudy.service';
import { HttpClient } from '@angular/common/http';
import { AuthorizedService } from '../../services/authorized/authorized.service';
import { MessageService } from '../../services/message/message.service';

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrl: './complaints.component.scss'
})


export class ComplaintsComponent {
  @ViewChild('complainForm') complainForm;
  
  category:string ='';
  participantId:number = 0;
  issue:string = '';
  roundId:string='';
  rounds:Array<any> = [];
  profstudyService:ProfstudyService;
  otherRound:string = '';
  otherCategory:string = '';
  roundsLoaded = false;
  reason = '';
  otherReason:string = '';
  product:string = '';
  hasErrors:boolean = false;
  submitSuccessFlag:boolean = false;
  authorizedService:AuthorizedService;
  messageService:MessageService;
  constructor(authorizedService: AuthorizedService, profstudyService:ProfstudyService, messageService:MessageService){
    this.profstudyService = profstudyService;
    this.profstudyService.profstudySubject.subscribe({
      next: this.gotRounds.bind(this),
      error: this.handleError.bind(this)
  });
    this.authorizedService = authorizedService;
    this.messageService = messageService;
  }
  handleError(res){
    debugger;

  }
  gotRounds(res:any){
    this.rounds = res.toReversed();
    this.roundsLoaded = true;
  }
  onSubmit(form){
    //do custom validation before submitting reason will have non-empty value and comments will have have text in it.
    this.hasErrors = false;
    if(this.reason == 'products'){
      if(this.product.length < 2){
        this.hasErrors = true;
        form.controls.productCtl.pristine = false;
      }
    }
    else if(this.reason == 'Proficiency Testing'){
      if(this.roundId.length < 2 || this.roundId == 'other' && this.otherRound.length < 2){
        this.hasErrors = true;
        form.controls.roundCtl.pristine = false;
      }
      if(this.category.length < 2){
        this.hasErrors = true;
        form.controls.categoryCtl.pristine = false;
      }
      if(this.category == 'other' && this.otherCategory.length < 2){
        this.hasErrors = true;
        form.controls.otherCategoryCtl.pristine = false;
      }
    }
    else{//other selected
      if(this.otherReason.length < 2){
        this.hasErrors = true;
        form.controls.otherReason.pristine = false;
      }
    }
    if(!this.hasErrors){
      //create json object from responses
      let request = {};
      request['product'] = this.product;
      request['comments'] = this.issue;
      request['participantId'] = this.participantId;
      if(this.reason == 'other'){
        request['reason'] = this.otherReason;
      }
      else{
        request['reason'] = this.reason;
      }

      if(this.roundId == 'other'){
        request['roundName'] == this.otherRound;
      }
      else{
        request['roundName'] == this.roundId;
      }
      if(this.category == 'other'){
        request['concernArea'] = this.otherCategory;
      }
      else{
        request['concernArea'] = this.category;
      }
      //submit the request...
      this.authorizedService.addMask("Submitting Form....");
      this.authorizedService.postJSON('/refcig-services/issueform',request,this.submitSuccess,this.handleError,this);
    }

  }
  submitSuccess(res):any{
    this.submitSuccessFlag = true;
    this.authorizedService.removeMask();
  }
  ngOnDestroy(){
    this.profstudyService.profstudySubject.unsubscribe();
  }
  resetForm(){
    this.submitSuccessFlag = false;
    this.category ='';
    this.participantId = 0;
    this.issue = '';
    this.roundId = '';
    this.otherRound = '';
    this.otherCategory = '';
    this.reason = '';
    this.otherReason = '';
    this.product = '';
    this.hasErrors = false;
  }
}
