import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../../services/products/products.service';


@Component({
  selector: 'app-productsmain',
  templateUrl: './productsmain.component.html',
  styleUrls: ['./productsmain.component.css']
})
export class ProductsmainComponent implements OnInit {

  products = []
  constructor   ( ) { }

  ngOnInit() {

  }

}
