
import { Injectable } from '@angular/core';
import { Observable ,  ReplaySubject, Subject } from 'rxjs';
import { User } from '../../models/user';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../message/message.service';
import { Profstudy } from '../../models/profstudy';
import { Router } from '@angular/router';
import { FileService } from '../file/file.service';
import { ProductsService } from '../products/products.service';
@Injectable({
  providedIn: 'root'
})
export class AuthorizedService {
  private loginSubject = new ReplaySubject<User>();
  public maskSubject = new Subject<any>();
	public userInfo:User = new User();
  private authority : String;
  public loggedIn : boolean;
  private requestPool = new Array<any>();
  public previousURL = String('');
  //for orders components
  public orderSubject  = new ReplaySubject<any[]>();
  public detailSubject = new ReplaySubject<any[]>();
  public billingSubject = new ReplaySubject<any[]>();
  public ctrpfileSubject = new ReplaySubject<any[]>();
  public profstudiesSubject = new ReplaySubject<Profstudy[]>();
  constructor(
    private http: HttpClient, 
    private messageService : MessageService, 
    private router : Router, 
    private productService : ProductsService,
    private fileService : FileService) { 
    this.loggedIn = false;
    this.maskSubject.next(true);
		var request = this.http.get('/refcig-services/me');
    request.subscribe((res) => this.updateLoginStatus(res));
	}
  addMask(message){
    this.maskSubject.next(message);
  }
  removeMask(){
    this.maskSubject.next(false);
  }
	requestLogin(formData){
    this.maskSubject.next("Loading Page Components....");
		if(environment.production){
			var request = this.http.post('/refcig-services/login', formData);
		}
		else{
			var request = this.http.post('/refcig-services/login', formData,{withCredentials:true});
		}
			request.subscribe((res) => this.updateLoginStatus(res),	(err) => this.onLoginFailure(err))
  }
	updateLoginStatus(user:any){
    this.userInfo.setValues(user);
    if(user.username != null){//user is logged in 
      this.loggedIn = true;
      this.authority = this.userInfo.authorityInstitution[0].authority;
      let isAdmin = false;
      this.userInfo.authorityInstitution.forEach(function(auth){
        if(auth.authority.toLowerCase().includes('admin')){
          isAdmin = true;
        }
      })

      this.getOrders();
      this.getProfStudies();
      if(isAdmin){
        this.getAdminProducts();
      }
      this.executeRequestPool();
			var request = this.http.get('/refcig-services/authority/'+this.authority+'/addresses');
			request.subscribe((res) => this.setAddresses(res));
    }
    else{
      this.loggedIn = false;
      this.requestPool = [];
      this.productService.removeAdminProducts();
      this.loginSubject.next(user);
      this.maskSubject.next(false);
    }
		
	}
	setAddresses(res){
		this.userInfo['addresses'] = res;
    this.loginSubject.next(this.userInfo);
    this.maskSubject.next(false);
	}
	getLoginStatus():Observable<User>{
		return this.loginSubject.asObservable();
  }
  
	requestLogOut(){
			var request = this.http.get('/refcig-services/logout');
		request.subscribe((res) => this.updateLoginStatus(res),	(err) => this.onLoginFailure(err))
	}
	onLoginFailure(err){
    this.maskSubject.next(false);
    this.loginSubject.next(err);
  }
  addToRequestPool(func){
    //functions added to the request pool should have the parameters already applied and 'this' context bound
    this.requestPool.push(func);
    if(this.loggedIn){
      this.executeRequestPool();
    }
  }
  executeRequestPool(){
    this.requestPool.forEach(func=>{
      func.call();
    })
      this.requestPool = [];
  }
  errorMessage(res){
    if(this != null){
        this.removeMask();//this is here because completePurchase on finalize component sets mask but doesn't return
        //todo rework so that on error the message is removed by complete purchase
    }
    
    if(res.error.message == 'Login unsuccessful.' && this.loggedIn == true){
      this.loggedIn = false;
      this.previousURL = this.router.url
      var a = this.loginSubject.next(res);
    }
    else if(this.loggedIn ==true){
      this.messageService.createMessage('error', res.message);

    }
    else{
      this.messageService.createMessage('error', res.message);
    }
  }
  errorMessageWithCallBack(callback, thisReference){
    return function(res){
        this.removeMask();//this is here because submit profstudy data needs it
      if(res.error.message == 'Login unsuccessful.' && this.loggedIn == true){
        this.loggedIn = false;
        this.previousURL = this.router.url;
        this.loginSubject.next(res);//not sure if any other components/services react to this, I think they expect this 
        //service to handle the logout error.
        this.messageService.createMessage('error','Your session has expired, you must log back in.')
      }
      else if(this.loggedIn ==true){
        if(res.error.message != null){
          this.messageService.createMessage('error', res.error.message);
        }
        else{
          this.messageService.createMessage('error', res.message);
        }
        
      }
      callback.call(thisReference);
    }
  }
  routeGuardLoggedOut(){
    //if a user's session times out and they attempt to access a protected endpoint they should be sent to the authorize page.
    //We have to wait until the routeguard service has recieved the logged out status update sent by errorMessage or errorMessageWithCallBack
    if(this.previousURL.length > 0){
      this.router.navigate(['/authenticate']);
    }
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //  END POINTS FOR GETTING ORDERS/ORDER DETAILS
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getOrders(){
    this.http.get('/refcig-services/authority/'+this.authority+'/purchases').subscribe((res)=>this.recievedOrders(res), (error)=> this.errorMessage(error));
  }
  recievedOrders(res){
    this.orderSubject.next(res);
  }
  //getBilling, getDetails, and getOrderCtrpfiles can get called before the user is authenticated in the event that they refresh the orderdetails page
  //by adding them to the request pool this ensures that they are performed after the user is logged in
  getBilling(purchaseId){
    var f = function(){
      return function(){this.http.get('refcig-services/authority/'+this.authority+'/purchase/'+purchaseId+'/billing').subscribe((res) => this.recievedBilling(res), (error) => this.errorMessage(error));}.apply(this,[purchaseId]);
    }
    this.addToRequestPool(f.bind(this));
  }
  getDetails(purchaseId){
    var f = function(){
      return function(){this.http.get('refcig-services/authority/'+this.authority+'/purchase/'+purchaseId+'/purchasedetails').subscribe((res) => this.recievedDetails(res), (error) => this.errorMessage(error));}.apply(this,[purchaseId]);
    }
    this.addToRequestPool(f.bind(this));
  }
  recievedDetails(res){
    this.detailSubject.next(res);
  }
  getOrderCtrpfiles(purchaseId){
    var f = function(){
      return function(){this.http.get('refcig-services/authority/'+this.authority+'/purchase/'+purchaseId+'/ctrpfiles').subscribe((res)=> this.recievedOrderCtrpfiles(res), (error) => this.errorMessage(error));}.apply(this,[purchaseId]);
    }  
    this.addToRequestPool(f.bind(this));

  }
  recievedOrderCtrpfiles(res){
    this.ctrpfileSubject.next(res);
  }
  
  recievedBilling(res){
    this.billingSubject.next(res);
  }
  recievedAdminProducts(res){
    this.productService.setAdminProducts(res);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Complete purchase endpoint
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  completePurchase(data,successCallback){
    this.http.post('/refcig-services/authority/' + this.userInfo.authorityInstitution[0].authority + '/completepurchase2', data).subscribe((res) => successCallback(res),(error) => this.errorMessage(error));
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Profstudy endpoints
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getProfStudies(){
    this.http.get('/refcig-services/authority/'+this.userInfo.authorityInstitution[0].authority+'/profstudies').subscribe((res)=>this.recievedProfstudies(res), (error) => this.errorMessage(error));
  }
  recievedProfstudies(res){
    this.profstudiesSubject.next(res);
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //Get Administrative products
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getAdminProducts(){
    this.http.get('/refcig-services/admin/products').subscribe((res)=>this.recievedAdminProducts(res),(error) => this.errorMessage(error));
  }
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //File Service endpoints
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  getFile(URL){
    if(this.loggedIn){
      this.fileService.getFile(URL, this.errorMessage, this);
    }
  }
  postFile(endpointURL, formdata, successCallback = null, errorCallback = null, thisReference = null){
    if(this.loggedIn){
      if (successCallback != null && thisReference !=null){
        if(errorCallback !=null){
          let func = this.errorMessageWithCallBack.apply(this,[errorCallback,thisReference]);
          this.fileService.postFile(endpointURL,formdata,successCallback,func.bind(this),thisReference);
        }
        else{//success callback but none for error
          this.fileService.postFile(endpointURL,formdata,successCallback,this.errorMessage,thisReference);
        }
      }
      else{//no callback
        this.fileService.postFile(endpointURL,formdata,null,this.errorMessage,null);
      }
    }
    
  }
  postJSON(endpoint, data, successCallback = null, errorCallback = null, thisReference = null){
    if(this.loggedIn){
      if(successCallback != null && thisReference !=null){
        if(errorCallback !=null){
          let func = this.errorMessageWithCallBack.apply(this,[errorCallback,thisReference]);
          this.fileService.postJSON(endpoint,data,successCallback,func.bind(this),thisReference);
        }
        else{//let authorizedservice handle any error messages
          this.fileService.postJSON(endpoint, data, successCallback, this.errorMessage, thisReference);
        }
      }
      else{//no callback

        this.fileService.postJSON(endpoint, data, null, this.errorMessage, null);
      }
    }
    
  }
}

